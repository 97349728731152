import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/152/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> T-Gel<sup>®</sup> Fort Šampon pro silné svědění 
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct singleproduct-new">
                <div className="prodimgcont prod-img">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/TGel-FORT_500x1000px.png"
                      alt="NEUTROGENA® T-Gel® Fort Šampon pro silné svědění"
                    />
                  </div>
                </div>
                <div className="prod-info info new-product">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup><br/>T-Gel<sup>®</sup> Fort<br/>Šampon pro silné svědění 
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          NOVINKA
                        </span>
                      </p>
                      <br/>
                      <p>
                      Snižuje tvorbu lupů již v prvním týdnu používání
                      </p>
                      <br/>
                      <p>
                      Zmírňuje velmi silné svědění 
                      </p>  
                      <br/>
                      <p>
                      NEUTROGENA T/Gel<sup>®</sup> Fort šampon byl speciálně vyvinut k maximální účinnosti na Vaší vlasové pokožce.  
                      </p> 
                      <br/>
                      <p>
                      Nedráždivé složení bez alkoholu zklidňuje vlasovou pokožku, dlouhodobě ji zbavuje lupů a dává jí zdravý vzhled. 
                      </p> 
                      <br/>
                      <p>
                      Zanechává Vaše vlasy a pokožku hlavy čisté a svěží.
                      </p> 
                      <br/>
                      <p>
                      Vyvinuto s dermatology. 
                      </p>                 
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      <p>Po dobu 6 týdnů používejte 2krát nebo 3krát týdně a potom 1krát nebo 2krát týdně.<br/>
                         1. Namočte si důkladně vlasy.<br/>
                         2. Naneste hojné množství šamponu na vlasy a vmasírujte jej<br/>
                         do pokožky hlavy.<br/>
                         3. Opláchněte.<br/>
                         4. Dle potřeby opakujte.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>UPOZORNĚNÍ:</dt>
                    <dd>
                      <p>Zabraňte vniknutí do očí. Pokud k němu dojde, vypláchněte si oči důkladně vodou. V případě podráždění nebo zčervenání snižte četnost používání nebo přestaňte výrobek používat. Pokud po 6 týdnech nedojde ke zlepšení, poraďte se se svým lékařem nebo lékárníkem.</p>
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>150 ml</dd>
                  </dl>
                </div>
                <div id="fb_35"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
